import BaseTopNav from '@/components/base/baseTopNav';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import FeaturesSettingTopTitle from '@/components/features/featuresSettingTopTitle';
import { getPrivacyAgreementApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { Spinner } from '@nextui-org/react';
import { useMount } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'umi';
import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';


/**
 * 公司简介页面
 * */
export default function CompanyProfileView() {
  const { state = {} as any } = useLocation();

  const { t } = useTranslation();
  //获取公司简介
  const {
    data: textInformation,
    run: getTextInformationReq,
    loading,
  } = useReq(getPrivacyAgreementApi, {
    // loadingDefault: false,
    manual: true,
  });
  useMount(() => {
    getTextInformationReq({
      type: state?.type,
    });
  });
  return (
    <div className="">
      <BaseTopNav
        // rightNode={
        //   <>
        //     {loading && (
        //       <Spinner
        //         className="mr-4 transition-opacity duration-700 ease-in-out"
        //         style={{ color: '#868d9a' }}
        //         size="sm"
        //       />
        //     )}
        //   </>
        // }
      />
      {/*顶部标题*/}
      <FeaturesSettingTopTitle
        title={state?.title}
        description={state?.description}
      />
      <div className="px-4 pt-6">
        <BaseLoadingDisplay loading={loading} list={!textInformation?.content?[]:[1]}>
            <FeaturesRenderText data={textInformation} className="" />
        </BaseLoadingDisplay>
      </div>
    </div>
  );
}
